.App {
  font-family: sans-serif;
  text-align: center;
}

.home {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.quote {
  color: #3d7cc9;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.15;
}

.doc-name {
  color: #3d7cc9;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.15;
}

.tagline {
  position: relative;
  font-size: 72px;
  color: #3d7cc9;
  position: absolute;
  left: 80px;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.quote-container {
  position: relative;
  display: flex;
  align-items: center;
}
/* .home-image1 {
  position: absolute;
} */
/* 
p {
  word-break: break-all; !important
} */

.MuiListItemIcon-root {
  min-width: 20px !important;
}
